/* Sleek off-white/silver background for the body */
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(to right, #000000, #4B0082, #000000); /* Black to dark purple and back to black */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

/* Overall container to hold both filter buttons and icon grid */
.app-container {
  display: flex;
  flex-direction: column; /* Make filters and grid stack vertically */
  width: 100%;
  max-width: 2400px;
  margin: 20px auto;
  text-align: center;
  box-sizing: border-box; /* Ensure padding/margin does not affect width */
}

/* Filter toggle button */
.toggle-filter-btn {
  padding: 10px 15px;
  background-color: #42a5f5;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 15px;
  transition: background-color 0.3s ease;
}

.toggle-filter-btn:hover {
  background-color: #1e88e5;
}

/* Filter icon */
.filter-icon {
  font-size: 24px;
  cursor: pointer;
  margin-bottom: 15px;
  transition: color 0.3s ease;
  background: linear-gradient(to right, #90caf9, #42a5f5); /* Same gradient as icons container */
  padding: 10px;
  border-radius: 50%; /* Round shape for the icon button */
  color: white;
}

.filter-icon.visible {
  color: white; /* Blue when filters are visible */
}

.filter-icon.hidden {
  color: #000; /* Grey when filters are hidden */
}

/* Top container for filter and icon */
.filter-top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

/* Filter buttons container */
.filter-buttons-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

/* Hidden class to toggle visibility without affecting layout */
.filter-buttons-container.hidden {
  opacity: 0;
  visibility: hidden;
  height: 0;
  overflow: hidden;
}

.filter-buttons-container.visible {
  opacity: 1;
  visibility: visible;
}

/* Filter buttons styling */
.filter-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Space between buttons */
}

/* Filter button styling */
.filter-buttons button {
  background: linear-gradient(to right, #90caf9, #42a5f5); /* Gradient from light blue to darker blue */
  border: 1px solid rgba(0, 0, 0, 0.1); /* Slight border for contrast */
  color: #000;
  padding: 10px 15px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
}

.filter-buttons button:hover {
  background: linear-gradient(to right, #90caf9, #42a5f5); /* Slightly darker blue to white on hover */
  transform: scale(1.02);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Slightly stronger shadow on hover */
}

.filter-buttons .active {
  background: linear-gradient(to right, #64b5f6, #42a5f5); /* Darker blue when active */
  color: #fff;
  transform: scale(1.05);
  box-shadow: 0 8px 14px rgba(0, 0, 0, 0.2); /* Stronger shadow when active */
}


/* Icon grid styling with blue gradient */
.icon-grid {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); /* Flexible columns */
  grid-auto-rows: 80px; /* Adjusted row height */
  row-gap: 10px; /* Space between rows */
  column-gap: 10px; /* Space between columns */
  padding: 20px;
  background: linear-gradient(to right, #0D47A1, #42a5f5, #0D47A1); /* Dark blue to light blue and back to dark blue */ /* Gradient background */
  border-radius: 20px;
  box-sizing: border-box; /* Ensure padding doesn't overflow */
  grid-template-columns: repeat(10, 1fr); /* Allow up to 10 icons per row */
}

/* Icon tiles */
.icon {
  width: 80px;
  height: 80px;
  margin: 0;
  padding: 0;
  border-radius: 8px;
  background-color: #ffffff; /* Plain white background */
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Ensure the icon fits perfectly in the tile without distortion */
.icon img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensure images maintain aspect ratio and fit within the container */
  display: block; /* Ensure image is treated as a block element */
  max-width: 100%;
  max-height: 100%;
}

/* Hover effect for the icon tile */
.icon:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

/* Tooltip for the icon description, positioned above the icon */
.tooltip {
  position: absolute;
  bottom: 110%; /* Adjusted to 110% for better positioning */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  white-space: nowrap;
  pointer-events: none;
  z-index: 9999; /* Very high z-index to ensure tooltip is always on top */
}

/* Show the tooltip on hover */
.icon:hover .tooltip {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 768px) {
  /* Make the grid more responsive for smaller screens */
  .icon-grid {
    grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
    padding: 20px;
    padding-right: 20px;
  }

  /* Adjust icon size for mobile */
  .icon {
    width: 60px;
    height: 60px;
  }

  .icon img {
    width: 100%;
    height: 100%;
  }

  .tooltip {
    bottom: 110%; /* Keep the tooltip positioned above the icon */
  }
}

/* Plus icon tile */
.add-icon-tile {
  background-color: #90caf9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.add-icon-tile:hover {
  transform: translateY(-10px); /* Lift effect on hover */
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}

.plus-sign {
  font-weight: bold;
}

/* Modal backdrop */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content styling */
.modal-content {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  text-align: left;
  font-family: 'Roboto', sans-serif;
}

/* Form header styling */
.modal-content h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
}

/* Label styling */
.modal-content label {
  font-size: 1rem;
  color: #333;
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
}

/* Input and textarea styling */
.modal-content input[type="text"],
.modal-content input[type="url"],
.modal-content textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f7f7f7; /* Light grey background */
  margin-bottom: 15px;
  box-sizing: border-box;
  font-size: 1rem;
  color: #333;
  transition: border-color 0.3s ease;
}

/* Focus state for inputs */
.modal-content input[type="text"]:focus,
.modal-content input[type="url"]:focus,
.modal-content textarea:focus {
  border-color: #42a5f5; /* Blue border on focus */
  outline: none;
}

/* Button container */
.form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

/* Submit button styling */
.submit-button {
  padding: 10px 20px;
  background: linear-gradient(to right, #64b5f6, #42a5f5); /* Blue gradient */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
  font-size: 1rem;
}

.submit-button:hover {
  background: linear-gradient(to right, #42a5f5, #2196f3);
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Slight shadow */
}

/* Cancel button styling */
.cancel-button {
  padding: 10px 20px;
  background-color: #f44336; /* Red background for cancel */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s ease;
}

.cancel-button:hover {
  background-color: #d32f2f; /* Darker red on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .modal-content {
    width: 90%;
  }
}

/* Checkbox container for tag selection */
.tag-checkboxes {
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); /* Adjust the min-width for flexibility */
  gap: 10px; /* Space between checkboxes */
}

.tag-checkbox {
  display: flex;
  align-items: center;
}

.tag-checkbox input[type="checkbox"] {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.tag-checkbox label {
  font-size: 1rem;
  color: #333;
  cursor: pointer;
}

.tag-checkbox input[type="checkbox"]:checked {
  accent-color: #42a5f5; /* Blue accent to match the theme */
}

/* Container for the title and icon */
.page-title-container {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Left justify the title */
  width: 100%;
  padding: 60px 0; /* Remove background and adjust padding */
  max-width: 1200px; /* Limit the width of the container */
  margin: 20px auto; /* Center the container horizontally */
}

/* Title icon */
.page-title-icon {
  width: 80px; /* Increase the size of the icon */
  height: 80px;
  margin-right: 20px; /* Space between icon and title text */
}

/* Title text */
.page-title {
  font-size: 3rem; /* Increase the font size of the title */
  color: #333; /* Darker color for the title */
  font-weight: bold;
  font-family: 'Roboto', sans-serif; /* Consistent font family */
  letter-spacing: 1px; /* Slight letter spacing for a modern look */
}

/* Button style matching the app's sleek design */
.sleek-button {
  background: linear-gradient(to right, #90caf9, #42a5f5); /* Matching the gradient used in your app */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
}

.sleek-button:hover {
  background: linear-gradient(to right, #64b5f6, #2196f3); /* Slightly darker gradient on hover */
  transform: translateY(-3px); /* Slight hover effect */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.sleek-button:disabled {
  background: #ddd; /* Grey background when disabled */
  cursor: not-allowed;
}

.sleek-button:active {
  transform: translateY(0); /* No lift effect when clicked */
  box-shadow: none; /* Remove shadow when active */
}

/* Page content container */
.page-content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Side panel for About and Terms, styled like the icon grid */
.side-panel {
  position: fixed;
  top: 100px;
  right: -400px; /* Hidden off-screen by default */
  width: 300px;
  height: 100%;
  background-color: rgba(247, 247, 247, 0.9); /* Less transparent */
  padding: 20px;
  box-shadow: -4px 0 12px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;
  z-index: 1001;
  border-radius: 20px 0 0 20px; /* Rounded corners for the panel */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
}

.side-panel.open {
  right: 0; /* Slide in when open */
}

.side-panel h2 {
  font-size: 1.5rem;
  color: #000; /* White title to match the grid */
  margin-bottom: 20px;
}

.side-panel p {
  font-size: 1rem;
  color: #000; /* White text for readability */
  line-height: 1.6;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.2); /* Light transparent background for text */
  border-radius: 8px;
  margin-bottom: 15px;
}

/* Navbar for the About and Terms buttons */
.navbar {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 20px;
}

.navbar a {
  color: #000; /* Inactive (grey) color */
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  padding: 10px 15px;
  background: linear-gradient(to right, #90caf9, #42a5f5);
  border-radius: 5px;
  transition: background 0.3s ease, color 0.3s ease;
}

.navbar a.active {
  background: #1e88e5; /* Active background color */
  color: white; /* Active text color */
}

.navbar a:hover {
  background: linear-gradient(to right, #64b5f6, #42a5f5); /* Hover effect */
  color: white; /* Text color changes to white on hover */
}

.navbar a:not(.active) {
  color: #000; /* Ensure inactive buttons are grey */
}

/* Container for the page title */
.page-title-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns everything to the left */
  justify-content: flex-start;
  margin: 0; /* Ensure no margin on the left */
  padding-left: 0; /* No padding to the left */
  text-align: left;
  width: 100%;
  max-width: 100%; /* Ensure full width to align content to the left */
}

/* Flexbox for icon and title */
.page-title-content {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Ensure the content is aligned left */
  width: 100%;
}

/* Logo styling (smaller to fit next to text) */
.page-title-icon {
  width: 50px; /* Smaller size to align with text */
  height: auto;
  margin-right: 15px; /* Space between the icon and text */
  border-radius: 50%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.page-title-icon:hover {
  transform: scale(1.1);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

/* Title text styling */
.page-title {
  font-size: 2.5rem; /* Slightly smaller to align with icon */
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  color: #1e88e5;
  letter-spacing: 1.5px;
  text-transform: lowercase; /* Lowercase as per request */
  background: linear-gradient(to right, #42a5f5, #64b5f6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: color 0.3s ease, transform 0.3s ease;
}

/* Caption text styling */
.page-caption {
  font-size: 0.9rem; /* Smaller font for the caption */
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  color: white; /* Soft grey for a subtle look */
  margin-top: 5px; /* Reduced margin to bring it closer to the main title */
  letter-spacing: 0.5px;
  line-height: 1.4;
  max-width: 100%; /* Ensure it fits in one line */
  text-transform: lowercase; /* Force lowercase */
}

.page-caption:hover {
  color: #333; /* Darker grey on hover */
}

/* Left Filter Panel */
.filter-side-panel {
  position: fixed;
  top: 200px;
  left: -400px; /* Initially hidden off-screen */
  width: 250px;
  height: 60vh;
  bottom: -200px;
  background-color: rgba(247, 247, 247, 0.9); /* Less transparent */
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 0px 20px 20px 0px; /* Rounded corners for the panel */
  padding: 20px;
  transition: left 0.3s ease;
  z-index: 1000;
}

.filter-side-panel.open {
  left: 0; /* Slide in from the left when open */
}


/* Navbar styling */
.navbar {
  display: flex;
  gap: 15px;
  padding: 20px;
  background-color: transparent; /* Transparent background */
}

.navbar a {
  cursor: pointer;
  text-decoration: none;
  font-size: 1rem;
  color: #000;
}

.navbar a.active {
  color: #007bff; /* Highlight the active panel */
}

/* General button and panel styling */
button {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* Preview Box for Modal */
.preview-box {
  margin-top: 20px;
  text-align: center;
}

.preview-box img {
  max-width: 100%;
  border-radius: 8px;
  cursor: pointer; /* Make the screenshot clickable */
}
